import styled from '@emotion/styled'
import { useRouter } from 'next/router'
import { useSelector } from 'react-redux'

import alertConfig from '@/redux/alert/config'
import { getBannerKeys, getVisibleBannerKey } from '@/redux/alert/selectors'
import { breakpoint } from '@/theme'

import Banner from './component'

import { InferProps, bool } from 'prop-types'

export default function BannerContainer({ relative, inCartDrawer }: InferProps<typeof BannerContainer.propTypes>) {
  const router = useRouter()

  const bannerKeys = useSelector(getBannerKeys)
  const alertKey = getVisibleBannerKey(bannerKeys, router.pathname, router.query) as string

  // Not all alertKeys trigger banners so we can have an alertKey but not have a banner to render
  // Use the alert config to check if the alertKey will render a banner
  // eslint-disable-next-line no-prototype-builtins
  if (!alertKey || !alertConfig[alertKey].hasOwnProperty('banner')) return null

  return (
    <BannerSpacerDiv inCartDrawer={inCartDrawer}>
      <Container relative={relative}>
        <Banner alertKey={alertKey} inCartDrawer={inCartDrawer} />
      </Container>
    </BannerSpacerDiv>
  )
}

BannerContainer.propTypes = {
  inCartDrawer: bool,
  relative: bool
}

const BannerSpacerDiv = styled.div<{ inCartDrawer: boolean }>`
  height: ${({ inCartDrawer }) => (inCartDrawer ? 'auto' : '64px')};
  margin-top: ${({ inCartDrawer, theme }) => (inCartDrawer ? `${theme.spacing.medium}px` : '0')};
  margin-bottom: ${({ inCartDrawer, theme }) => (inCartDrawer ? `${theme.spacing.default}px` : '0')};
  @media (max-width: ${breakpoint.max.lg}px) {
    height: ${({ inCartDrawer }) => (inCartDrawer ? 'auto' : '56px')};
  }
`

const Container = styled.div<{ relative: any }>`
  z-index: 11;
  left: 0;
  right: 0;
  top: 0;
  line-height: 1;
  transform: ${({ relative }) => (relative ? 'none' : 'translateZ(0)')};
  box-shadow: ${({ relative }) => (relative ? 'none' : '0 0.7rem 1.4rem 0 rgba(0, 0, 0, 0.15)')};
  position: ${({ relative }) => (relative ? 'relative' : 'fixed')};
`
